<template>
    <div v-if="reloadForm == false">
        <div v-if="is_exist == true">
            <div v-if="is_finish == false">     
                <div class="bg-bluegray-900 text-gray-100 p-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap">
                    <div class="font-bold mr-8">SURVEY APP</div>
                </div>
                <Error :errors="errors"/>
                <div class="grid">
                    <div class="field col-12 lg:col-6 lg:col-offset-3">
                        <div class="m-5 surface-card p-4 shadow-2 border-round">
                            <div class="text-center mb-5">
                                <img src="/favicon.png" alt="Image" height="50" class="mb-3">
                                <div class="text-900 text-3xl font-medium mb-3">Selamat Datang Dalam Market Test <b>Gilus Harum</b></div>
                                <span class="text-600 font-medium line-height-3">Silahkan jawab pertanyaan di bawah ini untuk mendapatkan sample product</span>
                                <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Gilus Harum!</a>
                                <br>
                                <span class="text-600 font-bold line-height-3">No. WA {{this.no_wa}} </span>
                            </div>
                            <hr>
                            <div class="w-full md:w-10 mx-auto">
                                <label class="block text-900 text-xl font-medium mb-2">{{this.forms[0].pertanyaan}}</label>
                                <Dropdown v-model="forms[0].jawaban" required="true" :options="dataDropdownIsKonsumsi" optionLabel="name" optionValue="code" class="w-full mb-4" style="padding:.4rem;" placeholder="" />

                                <div v-if="forms[0].jawaban == 'Ya'">
                                    <label class="block text-900 font-medium text-xl mb-2">{{this.forms[1].pertanyaan}}</label>
                                    <Dropdown v-model="forms[1].jawaban" required="true" :options="dataDropdownKopi" optionLabel="name" optionValue="code" class="w-full mb-4" style="padding:.4rem;" placeholder="" @change="forms[1].others = null"/>
                                    <InputText required="true" v-model.trim="forms[1].others" type="text" class="w-full mb-4" placeholder="Tuliskan merk kopi yang biasa anda konsumsi" style="padding:1rem;" v-if="forms[1].jawaban == 'Others'"/>

                                    <label class="block text-900 font-medium text-xl mb-2">{{this.forms[2].pertanyaan}}</label>
                                    <Dropdown v-model="forms[2].jawaban" required="true" :options="dataDropdownFrekuensi" optionLabel="name" optionValue="code" class="w-full mb-4" style="padding:.4rem;" placeholder="" />
                                </div>
                            
                                <Button label="Simpan" :loading="loading" @click="saveNew" class="w-full p-3 text-xl mt-3"></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="is_finish == true"> 
                <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
                    <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
                        <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(247, 149, 48, 0.4) 10%, rgba(247, 149, 48, 0) 30%);">
                            <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                                <div class="grid flex flex-column align-items-center" v-if="this.is_terima_hadiah == 1">
                                    <p class="text-5xl">🎉</p>
                                    <h1 class="text-900 font-bold text-4xl lg:text-5xl mb-2 text-center">Selamat Anda Berhak Mendapatkan {{this.msg}}✨</h1>
                                    <span class="text-600 text-center">Silahkan screenshot barcode dibawah ini untuk penukaran {{this.msg}}</span>
                                    <Image :src='"https://tuas.my.id/qrcode/index.php?data="+this.urlKonfirmasi' alt="Image" width="250" />
                                    <span class="text-600 text-center">Terimakasih sudah berpartisipasi dalam market test Gilus Harum</span>
                                </div>
                                <div class="grid flex flex-column align-items-center" v-else>
                                    <p class="text-5xl">✨</p>
                                    <h1 class="text-900 font-bold text-4xl lg:text-5xl mb-2 text-center">Terimakasih sudah berpartisipasi dalam market test Gilus Harum</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="is_exist == false">
            <NoAccess />
        </div>
    </div>
    <div v-else>
        <ReloadForm />
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import NoAccess from '../../../components/new/survey/NoAccess.vue';
import ReloadForm from '../../../components/new/survey/ReloadForm.vue';
import Error from '../../../components/Error.vue';


export default {
    components: {
        'NoAccess': NoAccess,
        'ReloadForm': ReloadForm,
		'Error': Error,
    },
    data() {
        return {
            // loading
            reloadForm: false,
            loading: false,
            loadingDropdownKopi: false,
            loadingDropdownFrekuensi: false,

            // dataDropdown
            dataDropdownKopi: [
                {name: 'Kapal Api Special Mix (kopi hitam)', code: 'Kapal Api Special Mix (kopi hitam)'},
                {name: 'GoodDay', code: 'GoodDay'},
                {name: 'Luwak', code: 'Luwak'},
                {name: 'Indocafe Coffee Mix', code: 'Indocafe Coffee Mix'},
                {name: 'ABC Susu', code: 'ABC Susu'},
                {name: 'Top Kopi Aren', code: 'Top Kopi Aren'},
                {name: 'Torabika Creamy Latte', code: 'Torabika Creamy Latte'},
                {name: 'Others', code: 'Others'},
            ],
            dataDropdownFrekuensi: [
                {name: '1-3 Gelas', code: '1-3 Gelas'},
                {name: '4-6 Gelas', code: '4-6 Gelas'},
                {name: '7 Gelas Atau Lebih', code: '7 Gelas Atau Lebih'},
            ],
            dataDropdownIsKonsumsi: [
                {name: 'Ya', code: 'Ya'},
                {name: 'Tidak', code: 'Tidak'},
            ],

            forms: [
                {
                    "kode_pertanyaan": "SP001",
                    "pertanyaan": "Apakah anda mengkonsumsi kopi?",
                    "jawaban": "Ya",
                    "urutan": "1",
                    "section": null
                },
                {
                    "kode_pertanyaan": "SP002",
                    "pertanyaan": "Merek kopi apa yang biasa anda konsumsi?",
                    "jawaban": null,
                    "urutan": "2",
                    "others": null,
                    "section": null
                },
                {
                    "kode_pertanyaan": "SP003",
                    "pertanyaan": "Berapa gelas anda konsumsi kopi dalam satu minggu?",
                    "jawaban": null,
                    "urutan": "3",
                    "section": null
                }
            ],

            no_wa: null,
            urlKonfirmasi: null,
            is_terima_hadiah: 0,
            is_finish: false,
            is_exist: false,
            msg: null,
            keteranganHadiah: null,
            hadiah: false,
        }
    },
    created() {
        this.getItemById(this.$route.params.id);
        this.no_wa = this.$route.params.id;
    },
	mounted() {
        this.$store.commit('setErrors', {});
	},
    computed:{
        ...mapGetters(['errors']),
    },
    methods: {
        saveNew() {
            this.loading = true;

            if(this.forms[0].jawaban == 'Ya'){

                const others = this.forms[1].jawaban == 'Others';

                if(this.forms[1].jawaban == null || this.forms[1].jawaban == "" || 
                   this.forms[2].jawaban == null || this.forms[2].jawaban == "" || (others && (this.forms[1].others === null || this.forms[1].others === ""))){
                    alert('Silahkan isi field yang ada terlebih dahulu');
                    this.loading = false;
                    return 
                }

                this.hadiah = true;
                this.keteranganHadiah = "30 Sachet Free Sample Gilus Harum";
            }

            if(this.forms[0].jawaban == "Tidak"){
                this.forms[1].jawaban = null;
                this.forms[2].jawaban = null;
                this.forms[1].others = null;

                this.hadiah = false;
                this.keteranganHadiah = null;
            }

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/survey/simpan-jawaban',
                data: {
                    "status": 'survey 4',
                    "no_hp": this.no_wa,
                    "answer": this.forms,
                    "isHadiah": this.hadiah == true ? '1' : '0',
                    "keteranganHadiah": this.keteranganHadiah,
                    "tahap": '1',
                },
            })
            .then((res) => {
                this.data = res.data;
                setTimeout(() => {
                    this.getItemById(this.no_wa);
                    this.loading = false;
                }, 300);
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
            });
        },
        // checkPeserta
        getItemById(id) {
            this.reloadForm = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/survey/get-survey',
                data: {
                    "status": 'survey 4',
                    "no_hp": id,
                },
            })
            .then((res) => {
                this.reloadForm = false;
                this.data = res.data;

                if (res) {
                                        
                   if(this.data.message == "Peserta tidak ditemukan"){    
                        this.is_exist = false;
                        return;
                   }else if(this.data.message == "Survey sudah di isi" || this.data.message == "Hadiah sudah diterima" ){
                        this.is_exist = true;
                        this.is_finish = true;
                        this.is_terima_hadiah = this.data.data.data.isHadiah;
                        this.urlKonfirmasi = window.location.hostname + '/konfirmasi-hadiah/' + '4/' + this.data.data.data.peserta.noHp;
                        this.msg = this.data.data.data.keteranganHadiah;
                        return;
                   }else{
                        this.is_exist = true;
                        this.is_finish = false;
                        return;
                   }
                }
            })
            .catch((err) => {
                console.log(err);
                this.reloadForm = false;
            });
        },
    },
}
</script>